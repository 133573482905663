import axios from "axios";
import { Advertisers } from "../types/typesAdvertisers.interface";
import { ResponseWrapper } from "../types/typesResponseWrapper.interface";
import { useAccessStore } from "../store/access";

export const getAdvertisers = (): Promise<ResponseWrapper> => {
  const storeAccess = useAccessStore();
  return axios
    .get(`${process.env.VUE_APP_ADV_API_URL}advertiser`, {
      headers: {
        Authorization: "Bearer " + storeAccess.accessToken,
      },
    })
    .then((val) => {
      return {
        success: true,
        data: <Advertisers>val.data,
      };
    })
    .catch(() => {
      return {
        success: false,
        data: null,
      };
    });
};
