import { constants } from "@/constants";

export const globalFilters = {
  numberToTruncatedString(num: number, isInteger?: boolean): string {
    if (num >= 1000000) {
      return `${Math.trunc(num / 100000) / 10}M`;
    } else if (num >= 1000 && !isInteger) {
      return `${Math.trunc(num / 100) / 10}K`;
    } else {
      if (isInteger) {
        return `${num.toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}`;
      } else {
        return `${num.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      }
    }
  },
  formatPhone(phoneNo: string): string {
    if (phoneNo.length === 10) {
      return (
        phoneNo.substring(0, 3) +
        "-" +
        phoneNo.substring(3, 6) +
        "-" +
        phoneNo.substring(6, 10)
      );
    } else {
      return phoneNo;
    }
  },
  isValidEmail(email: string): boolean {
    return constants.emailRegex.test(email);
  },
};
