
export default {
  name: "NavLink",
  props: {
    linkTitle: {
      type: String,
      default: "",
      required: true,
    },
    linkDestination: {
      type: String,
      default: "",
      required: false,
    },
    isActive: {
      type: Boolean,
      default: false,
      required: false,
    },
    isWithinDropdown: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};
