
export default {
  name: "Hamburger",
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
