import { useAccessStore } from "../store/access";
import { TokenContainer } from "../types/typesTokenContainer.interface";
import Cookies from "js-cookie";
import { constants } from "@/constants";
import { setRefreshTokenTimer } from "@/flows/refreshTimer";

export const hydrateAccess = (tokenContainer: TokenContainer): void => {
  const storeAccess = useAccessStore(),
    expireDateUTC =
      Date.now() + tokenContainer.expires_in * constants.millisecondsInASecond;
  // to debug access_token refresh time, change tokenContainer.expires_in on the above line to 10
  // this will manually set the access_token expire time to 10 seconds
  storeAccess.setAccessToken(tokenContainer.access_token);
  storeAccess.setExpiresIn(expireDateUTC);
  Cookies.set("fadRefresh", tokenContainer.refresh_token);
  setRefreshTokenTimer();
  window.scrollTo(0, 0);
};
