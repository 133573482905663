import { defineStore } from "pinia";
import { User } from "../types/typesUser.interface";

export const useUserStore = defineStore("user", {
  state: (): User => ({
    isLoggedIn: false,
    firstName: "",
    lastName: "",
    userId: null,
    email: "",
  }),
  actions: {
    setUserState(newState: User) {
      this.$state = newState;
    },
    clearUserState() {
      this.$state = <User>{
        isLoggedIn: false,
        firstName: "",
        lastName: "",
        userId: null,
        email: "",
      };
    },
  },
});
