import { useAccessStore } from "../store/access";
import { useContentStore } from "../store/content";
import { TokenContainer } from "../types/typesTokenContainer.interface";
import { TokenAccess } from "../types/typesTokenAccess.interface";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import { Advertiser } from "../types/typesAdvertisers.interface";
import { getAdvertisers } from "../services/serviceAdvertisers";
import { ResponseWrapper } from "../types/typesResponseWrapper.interface";
import { parseJSON } from "@/utils";

export const hydrateAdvertisers = (
  tokenContainer: TokenContainer
): Promise<ResponseWrapper> => {
  const storeAccess = useAccessStore(),
    storeContent = useContentStore(),
    accessDecode = jwt_decode<TokenAccess>(tokenContainer.access_token),
    hasOnlyOneAdvertiser =
      accessDecode.advertiserAccess.length === 1 &&
      !storeAccess.getPermissionsArray.includes("admin.advertiser.all"),
    cookieAdvIdString = Cookies.get("fadAdvSelect");
  let cookieAdvIdInt: number | null;
  if (cookieAdvIdString && parseJSON(cookieAdvIdString)) {
    cookieAdvIdInt = parseInt(parseJSON(cookieAdvIdString));
    if (process.env.VUE_APP_DEBUG_LOGS) {
      console.log(
        "parsed adv from cookies, with id " +
          parseInt(parseJSON(cookieAdvIdString))
      );
    }
  } else {
    cookieAdvIdInt = null;
  }
  // there are two scenarios where the user doesn't have to select an advertiser manually:
  // 1. they only have one advertiser. in this case, we also set their "cookie" advertiser to this
  // 2. there is a valid, parseable cookie that contains their previous selection. in this case, we still have to verify that they have access to this advertiser. if not, they are kicked out to /accounts
  return getAdvertisers().then((response) => {
    const idToSearchFor = hasOnlyOneAdvertiser
      ? accessDecode.advertiserAccess[0]
      : cookieAdvIdInt;
    if (response.success) {
      const advertiserMatch = response.data.advertisers.find(
        (advertiser: Advertiser) => advertiser.id === idToSearchFor
      );
      storeContent.setAvailableAdvertisers(response.data.advertisers);
      if (advertiserMatch) {
        storeContent.setActiveAdvertiser(advertiserMatch);
        Cookies.set("fadAdvSelect", JSON.stringify(advertiserMatch.id));
        return {
          success: true,
          data: advertiserMatch,
        };
      } else {
        if (process.env.VUE_APP_DEBUG_LOGS) {
          console.log(
            "Error: No matching advertiser found to hydrate application and auto-route to dashboard",
            response.data
          );
        }
        Cookies.remove("fadAdvSelect");
        return {
          success: false,
          data: null,
        };
      }
    } else {
      if (process.env.VUE_APP_DEBUG_LOGS) {
        console.log(
          "Error: Cannot get advertisers to hydrate application and auto-route to dashboard",
          response.data
        );
      }
      return {
        success: false,
        data: null,
      };
    }
  });
};
