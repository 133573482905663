import { useUserStore } from "../store/user";
import { useAccessStore } from "../store/access";
import { logInUser } from "@/services/serviceLogin";
import { hydrateAccess } from "./hydrateAccess";
import { constants } from "@/constants";

export const setRefreshTokenTimer = (): void => {
  const storeUser = useUserStore(),
    storeAccess = useAccessStore(),
    getNewTokens = (): void => {
      const tokenIsExpired = (): Boolean =>
        Date.now() >= storeAccess.expiresInUTC;
      if (process.env.VUE_APP_DEBUG_LOGS) {
        console.log(
          `Checking timer, Date.now(): ${Date.now()} storeAccess.expiresInUTC: ${
            storeAccess.expiresInUTC
          }`
        );
      }
      if (storeUser.isLoggedIn && tokenIsExpired()) {
        if (process.env.VUE_APP_DEBUG_LOGS) {
          console.log("Timer expired, refreshing token...");
        }
        logInUser().then((response) => {
          if (response.success) {
            hydrateAccess(response.data);
            if (process.env.VUE_APP_DEBUG_LOGS) {
              console.log("Token refreshed, timer reset.");
            }
          } else {
            if (process.env.VUE_APP_DEBUG_LOGS) {
              console.log(
                "Token refresh attempt failed, logging out user: " +
                  response.data
              );
            }
            storeAccess.logOut();
          }
        });
      } else if (!storeUser.isLoggedIn) {
        clearInterval(timer);
        if (process.env.VUE_APP_DEBUG_LOGS) {
          console.log("Timer cleared.");
        }
      }
    },
    timer = setInterval(
      getNewTokens,
      constants.millisecondsInASecond * constants.refreshIntervalInSecondsAccess
    );
};
