import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    alt: "",
    class: "icon-figg",
    src: require(`@/assets/images/icons/${$props.filename}.svg`),
    style: _normalizeStyle(`width: ${$props.width}px;`)
  }, null, 12, _hoisted_1))
}