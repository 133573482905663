
export default {
  name: "Icon",
  props: {
    filename: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: false,
      default: 24,
    },
  },
};
