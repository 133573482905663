import { useUserStore } from "../store/user";

export const initPendo = () => {
  (function (apiKey) {
    (function (p, e, n, d, o) {
      var v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? "unshift" : "push"](
                [m].concat([].slice.call(arguments, 0))
              );
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = !0;
      y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, "script", "pendo");
  })(process.env.VUE_APP_PENDO_KEY);
  // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
  // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless we set it
  // eslint-disable-next-line no-undef
  pendo.initialize({
    disableCookies: true,
    disablePersistence: true,
    disableGuides: true,
  });
};

export const setPendo = () => {
  // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless we set it
  // eslint-disable-next-line no-undef
  if (pendo) {
    const storeUser = useUserStore();
    if (storeUser.userId) {
      // eslint-disable-next-line no-undef
      pendo.identify({ visitor: { id: storeUser.userId.toString() } });
    } else {
      // eslint-disable-next-line no-undef
      pendo.clearSession();
    }
  }
};
