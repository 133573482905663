<script lang="js">
export default {
  name: "Footer",
  data: () => ({
    showGraphic: false,
  }),
  watch: {
    $route() {
      const path = this?.$route?.path;
      if (path) {
        if (path.includes('login') || path.includes('resetpassword')) {
          this.showGraphic = true;
        } else {
          this.showGraphic = false;
        }
      }
    }
  }
};
</script>

<template>
  <footer>
    <img
      v-if="showGraphic"
      alt="Chase background decoration"
      class="blue-line-gradient"
      :src="require('@/assets/images/blue-line-gradient.svg')"
    />
    <div>&copy;&nbsp;JPMC&nbsp;|&nbsp;</div>
    <a
      href="https://www.chase.com/digital/resources/privacy-security/"
      style="text-decoration: none"
    >
      <div class="link-title">Privacy Policy</div>
    </a>
    <div>&nbsp;|&nbsp;</div>
    <router-link to="/support" style="text-decoration: none">
      <div class="link-title">Support</div>
    </router-link>
  </footer>
</template>

<style lang="scss" scoped>
footer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin-top: auto;
  min-height: 95px;

  .blue-line-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height: 130px;
    pointer-events: none;
  }

  .link-title {
    color: $primaryBlue;
  }
}
</style>
