import { defineStore } from "pinia";
import { Access } from "../types/typesAccess.interface";
import { TokenAccess } from "../types/typesTokenAccess.interface";
import { useUserStore } from "../store/user";
import { useContentStore } from "../store/content";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import { logOutUser } from "@/services/serviceLogout";
import { setPendo } from "@/flows/pendo";
import Router from "../router";

export const useAccessStore = defineStore("access", {
  state: (): Access => ({
    accessToken: "",
    expiresInUTC: Date.now(),
    isGloballyLoading: false,
  }),
  getters: {
    getPermissionsArray(state) {
      const accessDecode = jwt_decode<TokenAccess>(state.accessToken);
      return accessDecode.scope.split(" ");
    },
    getAdvertisersArray(state) {
      const accessDecode = jwt_decode<TokenAccess>(state.accessToken);
      return accessDecode.advertiserAccess;
    },
  },
  actions: {
    setIsGloballyLoading(newValue: Boolean) {
      this.$state.isGloballyLoading = newValue;
    },
    setAccessToken(newToken: string) {
      this.$state.accessToken = newToken;
    },
    setExpiresIn(newDate: number) {
      this.$state.expiresInUTC = newDate;
    },
    logOut() {
      this.$state.isGloballyLoading = true;
      const useUser = useUserStore();
      const useContent = useContentStore();
      Router.push("/login");

      logOutUser().then(() => {
        useUser.clearUserState();
        useContent.clearContentState();
        this.$state = <Access>{
          accessToken: "",
          expiresInUTC: Date.now(),
        };
        Cookies.remove("fadRefresh");
        Cookies.remove("fadAdvSelect");
        this.$state.isGloballyLoading = false;
        setPendo();
      });
    },
  },
});
