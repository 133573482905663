import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-453c30a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "button-container",
  "aria-label": "Menu",
  "aria-controls": "profile-dropdown-navigation",
  "aria-haspopup": "menu"
}
const _hoisted_2 = {
  key: 0,
  id: "profile-dropdown-navigation",
  class: "account-dropdown",
  role: "menu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIIcon = _resolveComponent("UIIcon")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["account-btn", _ctx.open ? `is-open` : '']),
      role: "none"
    }, [
      _createVNode(_component_UIIcon, {
        class: "account-circle-line-white",
        filename: "account-circle-line-white",
        alt: "account button icon",
        role: "separator",
        width: 36
      }),
      _createVNode(_component_UIIcon, {
        class: "account-circle-line",
        filename: "account-circle-line",
        alt: "account button icon",
        role: "separator",
        width: 36
      })
    ], 2),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.open)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
              _renderSlot(_ctx.$slots, "default")
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}