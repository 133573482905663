import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7753f030"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "link-container",
  role: "menuitem"
}
const _hoisted_2 = {
  key: 1,
  class: "nav-link",
  style: {"text-decoration":"none"}
}
const _hoisted_3 = { class: "link-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    ($props.linkDestination)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: $props.linkDestination,
          "aria-current": $props.isActive ? `page` : null,
          class: "nav-link",
          style: {"text-decoration":"none"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(`link-title ${$props.isActive} ${
          $props.isWithinDropdown ? 'withinDropdown' : ''
        }`)
            }, _toDisplayString($props.linkTitle), 3)
          ]),
          _: 1
        }, 8, ["to", "aria-current"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString($props.linkTitle), 1)
        ])),
    _createElementVNode("div", {
      class: _normalizeClass(`rectangle-under-link ${$props.isActive} ${
        $props.isWithinDropdown ? 'withinDropdown' : ''
      }`),
      role: "none"
    }, null, 2)
  ]))
}