import { setBlockTracking as _setBlockTracking, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8cb2090c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loader-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (
      _setBlockTracking(-1),
      _cache[0] = _createElementVNode("div", {
        class: "loader",
        style: _normalizeStyle({
        backgroundImage:
          'url(' + require('@/assets/images/loader-fullpage-gray.png') + ')',
      })
      }, null, 4),
      _setBlockTracking(1),
      _cache[0]
    )
  ]))
}