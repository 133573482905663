
import NavLink from "../links/NavLink.vue";
import AccountButton from "../globalButtons/AccountButton.vue";
import UIButton from "../UIButton/UIButton.vue";
import Hamburger from "../hamburger/Hamburger.vue";
import { defineComponent } from "@vue/runtime-core";
import { mapState } from "pinia";
import { mapActions } from "pinia";
import { useUserStore } from "../../store/user";
import { useContentStore } from "../../store/content";
import { useAccessStore } from "../../store/access";

export default defineComponent({
  name: "Header",
  components: {
    NavLink,
    AccountButton,
    Hamburger,
    UIButton,
  },
  data: () => ({
    isMenuOpen: false,
    isAccountButtonOpen: false,
  }),
  computed: {
    ...mapState(useUserStore, ["isLoggedIn"]),
    ...mapState(useAccessStore, ["getPermissionsArray", "isGloballyLoading"]),
    ...mapState(useContentStore, ["activeAdvertiser"]),
  },
  methods: {
    ...mapActions(useAccessStore, ["logOut"]),
    clickMenuItem(isLogout?: Boolean): void {
      this.isMenuOpen = false;
      this.isAccountButtonOpen = false;
      if (isLogout) {
        this.logOut();
      }
    },
  },
});
