
import { PropType, defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "UIButton",
  props: {
    size: {
      type: String as PropType<"xmall" | "small" | "medium" | "large">,
      required: false,
      default: "small",
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    variant: {
      type: String as PropType<"normal" | "outline" | "unstyledBlue">,
      required: false,
      default: "normal",
    },
  },
});
