import axios from "axios";
import { LogoutResponse } from "../types/typesLogoutResponse.interface";
import { ResponseWrapper } from "../types/typesResponseWrapper.interface";
import { useAccessStore } from "../store/access";

export const logOutUser = (): Promise<ResponseWrapper> => {
  const storeAccess = useAccessStore();
  return axios
    .post(
      `${process.env.VUE_APP_AUTH_API_URL}oauth2/logout`,
      {},
      {
        headers: {
          Authorization: "Bearer " + storeAccess.accessToken,
        },
      }
    )
    .then((val) => {
      return {
        success: true,
        data: <LogoutResponse>val.data,
      };
    })
    .catch(() => {
      return {
        success: false,
        data: null,
      };
    });
};
