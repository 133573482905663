import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router/index";
import { globalFilters } from "./filters";
import { initPendo } from "@/flows/pendo";

import PrimeVue from "primevue/config";
import { definePreset } from "@primevue/themes";
import Aura from "@primevue/themes/aura";

const app = createApp(App),
  ChaseBlue = definePreset(Aura, {
    semantic: {
      primary: {
        50: "{sky.50}",
        100: "{sky.100}",
        200: "#005EB8",
        300: "#005EB8",
        400: "#005EB8",
        500: "#005EB8",
        600: "#005EB8",
        700: "#005EB8",
        800: "#004692",
        900: "{sky.900}",
        950: "{sky.950}",
      },
    },
  });

app.config.globalProperties.$filters = globalFilters;

app
  .use(createPinia())
  .use(router)
  .use(PrimeVue, {
    theme: {
      preset: ChaseBlue,
      options: {
        prefix: "p",
        darkModeSelector: "fake-dark-mode-selector",
        cssLayer: false,
      },
    },
  })
  .mount("#app");

initPendo();
