
require("@/assets/styles/reset.css");
import Header from "./components/header/Header.vue";
import Footer from "./components/footer/Footer.vue";
import UILoaderFullpage from "./components/UILoaderFullpage/UILoaderFullpage.vue";
import { defineComponent } from "vue";
import { mapState } from "pinia";
import { useUserStore } from "./store/user";
import { useAccessStore } from "./store/access";
import { constants } from "@/constants";

import "./App.scss";

export default defineComponent({
  name: "App",
  components: { Header, Footer, UILoaderFullpage },
  data: () => ({
    transitionName: "fadeLong",
    isLoaded: false,
    constants,
  }),
  computed: {
    ...mapState(useUserStore, ["isLoggedIn"]),
    ...mapState(useAccessStore, ["isGloballyLoading"]),
  },
  watch: {
    $route(to, from) {
      if (this.isLoaded) {
        this.transitionName = to.meta.order > from.meta.order ? "next" : "prev";
      } else {
        this.isLoaded = true;
      }
    },
  },
});
