
import { defineComponent } from "vue";
import { mapState } from "pinia";
import { useUserStore } from "../../store/user";
import UIIcon from "../UIIcon/UIIcon.vue";

export default defineComponent({
  name: "AccountButton",
  components: { UIIcon },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState(useUserStore, ["firstName"]),
  },
});
